import { ref, reactive, watch, onMounted,onUnmounted} from 'vue'
import {useRouter, useRoute} from 'vue-router'


export function useGetDevice(){
	let type = null;
	if(document.body.clientWidth>800){
		type = 'pc';
	}else{
		type = 'mobile';
	}
	// window.onresize = function(){
	// 	console.log('onresize')
	// 	if(document.body.clientWidth>1000){
	// 		type.value = 'pc';
	// 	}else{
	// 		type.value = 'mobile';
	// 	}
	// }
	return type;
}


export function useScrollToParamsId(){
	const route = useRoute();
	onMounted(()=>{
	    if(route.params.id){
            let elemTop = document.getElementById(route.params.id).offsetTop;
            document.documentElement.scrollTop = elemTop;
            document.body.scrollTop = elemTop;
         }
     })
}