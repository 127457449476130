import axios from 'axios'
import { message } from 'ant-design-vue';

// create an axios instance
const service = axios.create({
  baseURL:'https://admin.yuezengjian.com',
  timeout: 5000 // request timeout
})

//请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // if (store.getters.token) {
      // console.log("request拦截器");
      // 让每个请求携带token--['X-Token']为自定义key
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    // }
    // console.log(config);
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return error
    // return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // console.log(response.data);
    if(response.data.errcode===401){

    }else{
      return response.data;
    }
  },
  error => {
    console.log(error);
    // message.error({content:"网络超时，请稍后再试！"});
  }
)

export default service
